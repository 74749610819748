
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="currentComponent==='monthBill'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('monthBill')">月账单列表</div>
          <div v-if="currentComponent==='detail'" :class="currentComponent==='detail'? 'bottom-button-active':'bottom-button'" >月账单详情</div>
        </div>
        <component :is="currentComponent + 'Component'" ref="bottom" />
    </div>
</template>

<script>
export default {
  components: {
    monthBillComponent: (resolve) => {
      require(['./bottom/MonthBill'], resolve)
    },
    detailComponent: (resolve) => {
      require(['./bottom/detailSettlement'], resolve)
    }
  },
  props: {
  },
  data () {
    return {
      currentComponent: 'monthBill'
    }
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_bill_monthBillId', null)
      this.$store.commit('set_bill_pageType', val)
    }
  },
  computed: {
    pageType () {
      return this.$store.state.bill.pageType
    },
    beginUpdate () {
      return this.$store.state.bill.beginUpdate
    }
  },
  watch: {
    pageType () {
      this.currentComponent = this.pageType
    }
  }
}
</script>
